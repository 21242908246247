import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";

import { useFormikContext, Formik } from "formik";
import {Form, Row, Col, Button } from "react-bootstrap";
import * as Yup from "yup";

import "../../components/section/section.css";
import "./form-contact.css";

const HandleReset = (props) => {
    function usePrevious (value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }

    // eslint-disable-next-line react/prop-types
    const prevEnvoye = usePrevious(props.envoye);
    const { resetForm } = useFormikContext();

    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        if (prevEnvoye !== props.envoye) {
            resetForm();
        }
        // eslint-disable-next-line react/prop-types
    }, [props.envoye]);

    return null;
};

const RdvForm = (props) => {
    const { t } = useTranslation(["translation", "section"]);
    const patternPhoneNumber =
        /^(\+[0-9]{2}[.\-\s]?|00[.\-\s]?[0-9]{2}|0)([0-9]{1,3}[.\-\s]?(?:[0-9]{2}[.\-\s]?){4})$/;
    const patternImmat = /[A-HJ-NP-TV-Z]{2}[\s-]{0,1}[0-9]{3}[\s-]{0,1}[A-HJ-NP-TV-Z]{2}|[0-9]{2,4}[\s-]{0,1}[A-Z]{1,3}[\s-]{0,1}[0-9]{2}/;
    return (
        
            <div id="formRdv" className="form">
                <h2 className="titre text-center">
                    {t("rdv.title", { ns: "section" })}
                </h2>
                <p className="text-center mt-1 mb-5">
                    {t("contact.subTitle", { ns: "section" })}
                </p>
                <Row>
                    <Col>
                        <Formik
                            validateOnMount
                            initialValues={{
                                nom: "",
                                prenom: "",
                                numTelephone: "",
                                mail: "",
                                numeroImatriculation: "",
                                numeroHayon: "",
                                marqueHayon: "",
                                description: "",
                            }}
                            validationSchema={Yup.object({
                                nom: Yup.string()
                                    .min(
                                        3,
                                        t("contact.validation.min", {
                                            name: "nom",
                                            min: "2",
                                            type: "caractéres",
                                            ns: "section",
                                        })
                                    )
                                    .max(
                                        50,
                                        t("contact.validation.max", {
                                            name: "nom",
                                            max: "50",
                                            type: "caractéres",
                                            ns: "section",
                                        })
                                    )
                                    .required(t("contact.validation.required", { ns: "section" })),
                                prenom: Yup.string()
                                    .min(
                                        3,
                                        t("contact.validation.min", {
                                            name: "prénom",
                                            min: "2",
                                            type: "caractéres",
                                            ns: "section",
                                        })
                                    )
                                    .max(
                                        50,
                                        t("contact.validation.max", {
                                            name: "prénom",
                                            max: "50",
                                            type: "caractéres",
                                            ns: "section",
                                        })
                                    ),
                                mail: Yup.string()
                                    .email("Merci de renseigner une adresse mail valide")
                                    .required(t("contact.validation.required", { ns: "section" })),
                                numTelephone: Yup.string()
                                    .matches(patternPhoneNumber, "Numéro de téléphone non valide")
                                    .required(t("contact.validation.required", { ns: "section" })),
                                numeroImatriculation: Yup.string()
                                    .matches(patternImmat, "Numéro d'immatriculation non valide")
                                    .required(t("contact.validation.required", { ns: "section" })),
                                numeroHayon: Yup.number()
                                    .typeError(t("contact.validation.number", {
                                        name: "numéro de hayon",
                                        ns: "section",
                                    }))
                                    .positive("le Numéro de hayon doit être positif")
                                    .integer("Numéro de hayon doit étre un entier"),
                            
                                marqueHayon: Yup.string()
                                    .min(
                                        3,
                                        t("contact.validation.min", {
                                            name: "marque de hayon",
                                            min: "2",
                                            type: "caractéres",
                                            ns: "section",
                                        })
                                    )
                                    .max(
                                        100,
                                        t("contact.validation.max", {
                                            name: "marque de hayon",
                                            max: "100",
                                            type: "caractéres",
                                            ns: "section",
                                        })
                                    ),
                                description: Yup.string()
                                    .min(
                                        5,
                                        t("contact.validation.min", {
                                            name: "demande",
                                            min: "5",
                                            type: "caractéres",
                                            ns: "section",
                                        })
                                    )
                                    .max(
                                        500,
                                        t("contact.validation.max", {
                                            name: "demande",
                                            max: "500",
                                            type: "caractéres",
                                            ns: "section",
                                        })
                                    )
                                    .required(t("contact.validation.required", { ns: "section" })),
                            })}
                            onSubmit={async (values, { setSubmitting }) => {
                                props.validation(values);
                                setSubmitting(false);
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form onSubmit={formik.handleSubmit}>
                                        <HandleReset envoye={props.envoye} />
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="nom">
                                                    <Form.Label>
                                                        {t("contact.input.label", {
                                                            name: "Nom",
                                                            ns: "section",
                                                        })}
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder={t("contact.input.placeholder", {
                                                            name: "nom",
                                                            ns: "section",
                                                        })}
                                                        type="text"
                                                        {...formik.getFieldProps("nom")}
                                                    />
                                                    {formik.touched.nom && formik.errors.nom ? (
                                                        <small className="errorText">{formik.errors.nom}</small>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="prenom">
                                                    <Form.Label>
                                                        {t("contact.input.label", {
                                                            name: "Prénom",
                                                            ns: "section",
                                                        })}
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder={t("contact.input.placeholder", {
                                                            name: "prénom",
                                                            ns: "section",
                                                        })}
                                                        type="text"
                                                        {...formik.getFieldProps("prenom")}
                                                    />
                                                    {formik.touched.prenom && formik.errors.prenom ? (
                                                        <small className="errorText">
                                                            {formik.errors.prenom}
                                                        </small>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="numTelephone">
                                                    <Form.Label>
                                                        {t("contact.input.label", {
                                                            name: "Téléphone",
                                                            ns: "section",
                                                        })}
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder={t("contact.input.placeholder", {
                                                            name: "numéro de téléphone",
                                                            ns: "section",
                                                        })}
                                                        type="tel"
                                                        {...formik.getFieldProps("numTelephone")}
                                                    />
                                                    {formik.touched.numTelephone &&
                                                        formik.errors.numTelephone ? (
                                                        <small className="errorText">
                                                            {formik.errors.numTelephone}
                                                        </small>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="mail">
                                                    <Form.Label>
                                                        {t("contact.input.label", {
                                                            name: "Adresse mail",
                                                            ns: "section",
                                                        })}
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder={t("contact.input.placeholder", {
                                                            name: "adresse mail",
                                                            ns: "section",
                                                        })}
                                                        type="email"
                                                        {...formik.getFieldProps("mail")}
                                                    />
                                                    {formik.touched.mail && formik.errors.mail ? (
                                                        <small className="errorText">
                                                            {formik.errors.mail}
                                                        </small>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <Form.Group controlId="numeroImatriculation">
                                                    <Form.Label>
                                                        {t("contact.input.label", {
                                                            name: "Immatriculation",
                                                            ns: "section",
                                                        })}
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder={t("contact.input.placeholder", {
                                                            name: "Numéro d'immatriculation",
                                                            ns: "section",
                                                        })}
                                                        type="text"
                                                        {...formik.getFieldProps("numeroImatriculation")}
                                                    />
                                                    {formik.touched.numeroImatriculation &&
                                                        formik.errors.numeroImatriculation ? (
                                                        <small className="errorText">
                                                            {formik.errors.numeroImatriculation}
                                                        </small>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={4}>
                                                <Form.Group controlId="numeroHayon">
                                                    <Form.Label>
                                                        {t("contact.input.label", {
                                                            name: "Numéro de hayon",
                                                            ns: "section",
                                                        })}
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder={t("contact.input.placeholder", {
                                                            name: "Numéro de hayon",
                                                            ns: "section",
                                                        })}
                                                        type="number"
                                                        {...formik.getFieldProps("numeroHayon")}
                                                    />
                                                    {formik.touched.numeroHayon && formik.errors.numeroHayon ? (
                                                        <small className="errorText">
                                                            {formik.errors.numeroHayon}
                                                        </small>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group controlId="marqueHayon">
                                                    <Form.Label>
                                                        {t("contact.input.label", {
                                                            name: "Marque de hayon",
                                                            ns: "section",
                                                        })}
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder={t("contact.input.placeholder", {
                                                            name: "Marque de hayon",
                                                            ns: "section",
                                                        })}
                                                        type="text"
                                                        {...formik.getFieldProps("marqueHayon")}
                                                    />
                                                    {formik.touched.marqueHayon && formik.errors.marqueHayon ? (
                                                        <small className="errorText">
                                                            {formik.errors.marqueHayon}
                                                        </small>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="px-3">
                                            <Form.Group controlId="description">
                                                <Form.Label>
                                                    {t("contact.input.label", {
                                                        name: "Quel est votre demande ?",
                                                        ns: "section",
                                                    })}
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder={t("contact.input.placeholder", {
                                                        name: "demande",
                                                        ns: "section",
                                                    })}
                                                    {...formik.getFieldProps("description")}
                                                />
                                                {formik.touched.description && formik.errors.description ? (
                                                    <small className="errorText">
                                                        {formik.errors.description}
                                                    </small>
                                                ) : null}
                                            </Form.Group>
                                        </Row>
                                        <Button
                                            className="btn btn-perso-primary btn-perso-lg float-right"
                                            type="submit"
                                            disabled={!(formik.isValid && formik.dirty)}
                                        >
                                            Envoyer
                                        </Button>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Col>
                </Row>



            
        </div>
    );
};
RdvForm.propTypes = {
    validation: propTypes.func,
    envoye: propTypes.bool,
};
export default RdvForm;
