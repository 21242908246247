import React from "react";
import { useTranslation } from "react-i18next";
import {
  faWarehouse,
  faWrench,
  faOilCan,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "../../icones/icones";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import "./services.css";
import "../section.css";

const Services = () => {
  const { t } = useTranslation("section");
  return (
    <section id="services" className="text-center">
      <Container>
        <h2 className="titre">{t("service.title")}</h2>
        <Row>
          <Col xs={12} md={4} className="slide-top">
            <Icon icon={faWarehouse} />
            <h3>{t("service.service1")}</h3>
          </Col>
          <Col xs={12} md={4} className="slide-top">
            <Icon icon={faWrench} />
            <h3>{t("service.service2")}</h3>
          </Col>
          <Col xs={12} md={4} className="slide-top">
            <Icon icon={faOilCan} />
            <h3>{t("service.service3")}</h3>
          </Col>
        </Row>
        <Card border="dark" className="text-justify mt-5">
          <Card.Body>
            <p>{t("service.description")}</p>
            <Button
              className="btn btn-perso-primary btn-perso-lg mx-auto mb-4 mt-4 center"
              href="#formContact"
            >
              {t("service.buttonContact")}
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export default Services;
