import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Button, Card, Image, Spinner } from "react-bootstrap";
import useModal from "../../../hook/useModal";
import Modal from "../../modal/Modal";
import logoHydrotest from "../../../asset/images/logo_hydrotest.png";
import certificationHydroTest from "../../../asset/images/SP-HAYON-Certificat-Hydrotest.jpg";
import RdvForm from "../../../containers/form/fom-rdv";
import Alert from "../../alert/alert";
import useEmailSenderHook from "../../../hook/useEmailSender";

import "./controle.css";
import "../section.css";
import "../../modal/modal.css";

const Controle = () => {
  const sectionRefs = useRef(null);
  const { t } = useTranslation("section");
  const { isShowing: isCertificationShowed, toggle: toggleCertification } =
    useModal();
  const { isShowing: isRdvShowed, toggle: toggleModalrdv } =
    useModal();

  const { alert, loading, envoye, sendEmail, closeAlert } = useEmailSenderHook();

  return (
    <section id="controle" className="text-center" ref={sectionRefs[3]}>
      <Container>
        <h2 className="titre">{t("controle.title")}</h2>
        <h4>{t("controle.subTitle")}</h4>

        <Row className="mt-5">
          <Col xs={12} md={6} className="border-right">
            <Row>
              <Col>
                <p className="font-weight-bold">{t("controle.subText")}</p>
                <p className="text-justify mt-2">{t("controle.description")}</p>
              </Col>
              <Row>
                <Col>
                  <button className="modal-toggle" onClick={toggleCertification}>
                    <Image
                      src={logoHydrotest}
                      alt="logo Hydrotest"
                      style={{ maxHeight: "50px" }}
                      className="mx-auto d-flex mt-3"
                    />
                    voir notre certification
                  </button>
                  <Modal
                    isShowing={isCertificationShowed}
                    hide={toggleCertification}
                    title="Certification Hydrotest"
                    onClick={toggleCertification}
                    overlayClosed={true}
                  >
                    <Image
                      fluid={true}
                      src={certificationHydroTest}
                      alt="certification Hydrotest SP Hayon"
                    />
                  </Modal>
                </Col>
              </Row>
            </Row>
          </Col>


          <Col xs={12} md={6}>
            <Button
              className="btn btn-perso-primary btn-perso-lg mx-auto mb-4"
              onClick={toggleModalrdv}
            >
              {t("controle.buttonRdv")}
            </Button>
            <Modal
              isShowing={isRdvShowed}
              hide={toggleModalrdv}
              onClick={toggleModalrdv}
              type={'rdv'}
            >
              <RdvForm validation={sendEmail} envoye={envoye} />
              {loading && (
                <Spinner
                  className="text-center mt-3 mb-3 p-4 mx-auto d-flex"
                  animation="border"
                  variant="danger"
                />
              )}
              {!loading && alert.text && (
                <Alert
                  show={alert.show}
                  variant={alert.variant}
                  close={closeAlert}
                >
                  {alert.text}
                </Alert>
              )}

            </Modal>
            <Card border="dark" className="mt-5">
              <Card.Body className="text-center">
                {t("controle.horaire")}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Controle;     