import "./css/app.css";
import "./css/button.css";
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Menu from "./components/menu/menu";
import LandingPage from "./containers/landing-page/landing-page";
import Error404 from "./components/error/404/404";

const MentionLegal = lazy(() =>
  import("./containers/mention-legal/mention-legal")
);

function App() {
  return (
    <BrowserRouter basename="/">
      <Suspense fallback="loading">
        <Menu />
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/mention-legal" exact component={MentionLegal} />
          <Route render={() => <Error404 />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
