import React from "react";
import { useTranslation } from "react-i18next";
import imgErreur404 from "../../../asset/images/erreur404.jpg";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

const error404 = () => {
  const { t } = useTranslation();
  return (
    <section id="404">
      <Image
        src={imgErreur404}
        alt="erreur 404"
        className="img-fluid center auto"
      />
      <br />
      {t("404.notExist")}{" "}
      <Link to="/" className="alert-link">
        {t("404.home")}
      </Link>
      <br />
    </section>
  );
};
export default error404;
