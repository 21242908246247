import React from "react";
import { useTranslation } from "react-i18next";
import { Navbar, Nav, Image, Container } from "react-bootstrap";
import Scrollspy from "react-scrollspy";
import logo from "../../asset/images/logoSpHayon.png";
import logoHydroparts from "../../asset/images/logo_hydroparts.png";
import "./menu.css";

const menu = () => {
  const { t } = useTranslation();
  return (
    <Navbar fixed="top" id="menu" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <Image
            src={logo}
            alt="logo SP HAYON"
            style={{ maxHeight: "50px" }}
            className="mx-auto d-flex"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Scrollspy
            className="nav mr-auto"
            items={[
              "header",
              "services",
              "intervention",
              "controle",
              "marques",
              "formContact",
            ]}
            currentClassName="active"
            componentTag="div"
            offset={-50}
          >
            <Nav.Link href="/#header">{t("menu.home")}</Nav.Link>
            <Nav.Link href="/#services">{t("menu.services")}</Nav.Link>
            <Nav.Link href="/#intervention">{t("menu.intervention")}</Nav.Link>
            <Nav.Link href="/#controle">{t("menu.controle")}</Nav.Link>
            <Nav.Link href="/#marques">{t("menu.marques")}</Nav.Link>
            <Nav.Link href="/#formContact">{t("menu.contact")}</Nav.Link>
            <Image
              src={logoHydroparts}
              alt="logo Hydroparts"
              style={{ maxHeight: "50px", maxWidth: "100px" }}
              className="ml-3 d-flex"
            />
          </Scrollspy>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default menu;
