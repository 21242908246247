import React from "react";
import propTypes from "prop-types";
import { Alert } from "react-bootstrap";

const Alerte = (props) => (
  <Alert
    className="mt-4 mb-4"
    show={props.show}
    variant={props.variant}
    onClose={props.close}
    dismissible
  >
    <p>{props.children}</p>
  </Alert>
);

Alerte.propTypes = {
  show: propTypes.bool,
  variant: propTypes.string,
  close: propTypes.func,
  children: propTypes.string.isRequired,
};
export default Alerte;
