import React, { Component } from "react";
import propTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import ContoureRegion from "../../asset/contoure_31_81.json";

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 43.6043,
      lng: 1.4437,
    },
    zoom: 7,
    adresse: "44 allée Jean Moulin 31140 SAINT ALBAN",
    greatPlaceStyle: {
      // initially any map object has left top corner at lat lng coordinates
      // it's on you to set object origin to 0,0 coordinates
      position: "absolute",
      width: 40,
      height: 40,
      left: -40 / 2,
      top: -40 / 2,
      border: "5px solid #f44336",
      borderRadius: 40,
      backgroundColor: "white",
      textAlign: "center",
      color: "#3f51b5",
      fontSize: 16,
      fontWeight: "bold",
      padding: 4,
    },
    contoureStyle: {
      fillColor: "#6f360b",
      strokeColor: "#6f360b67",
      strokeWeight: 3,
    },
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_API_GOOGLE_API_KEY,
          }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onGoogleApiLoaded={({ map }) => {
            map.data.addGeoJson({ ...ContoureRegion });
            map.data.setStyle(this.props.contoureStyle);
          }}
          yesIWantToUseGoogleMapApiInternals
        ></GoogleMapReact>
      </div>
    );
  }
}
SimpleMap.propTypes = {
  center: propTypes.object,
  zoom: propTypes.number,
  contoureStyle: propTypes.object,
};
export default SimpleMap;
