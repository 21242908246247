// useEmailSenderHook.js
import { useState } from 'react';
import emailjs from "@emailjs/browser";

const useEmailSenderHook = () => {
  const [alert, setAlert] = useState({ variant: '', text: '', show: false });
  const [loading, setLoading] = useState(false);
  const [envoye, setEnvoye] = useState(false);

  const sendEmail = (data) => {
    setLoading(true);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        data,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(() => {
        setLoading(false);
        setAlert({
          variant: 'success',
          text: 'Email envoyé avec succès !', // Modifier le texte en fonction de vos besoins
          show: true,
        });
        setEnvoye(!envoye);
      })
      .catch(() => {
        setLoading(false);
        setAlert({
          variant: 'danger',
          text: 'Erreur lors de l\'envoi de l\'email.', // Modifier le texte en fonction de vos besoins
          show: true,
        });
      });
  };

  const closeAlert = () => {
    setAlert({ variant: '', text: '', show: false });
  };

  return {
    alert,
    loading,
    envoye,
    sendEmail,
    closeAlert,
  };
};

export default useEmailSenderHook;